import cn from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Toggle from 'shared/graphics/atoms/Toggle'
import ToolTip from 'shared/graphics/atoms/Tooltip'
import HideIcon from 'shared/graphics/icons/Hide.svg?component'

import scss from './style.module.scss'

interface Props {
  disabled?: boolean
  showHidden: boolean

  onChange?: () => void
}

const HideSelection = ({ disabled, onChange, showHidden }: Props) => {
  return (
    <ToolTip data={disabled && 'Nothing hidden yet'}>
      <div className={cn(scss.hide, disabled && scss.isDisabled)}>
        <HideIcon className={scss.hide__icon} />
        <span className={scss.hide__toggle}>
          <FormattedMessage defaultMessage={'Show hidden'} />
        </span>
        <Toggle onChange={onChange} value={showHidden} disabled={disabled} />
      </div>
    </ToolTip>
  )
}

export default HideSelection
