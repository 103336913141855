import { format } from 'date-fns'
import type { MutableRefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

interface UseCustomPlayer {
  videoRef: MutableRefObject<HTMLVideoElement | null>
  playerContainerRef?: MutableRefObject<HTMLDivElement | null>
  offset?: number
  duration?: number
  onTimeChange?: (time: number) => void
}

const useCustomPlayer = ({ videoRef, playerContainerRef, offset: offset_ = 0, duration: duration_, onTimeChange }: UseCustomPlayer) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(duration_ || 0)
  const [loaded, setLoaded] = useState(false)
  const [isMouseMoving, setIsMouseMoving] = useState(false)
  const mouseMovingTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [volume, setVolume] = useState(1)
  const [muted, setMuted] = useState(false)

  useEffect(() => {
    onTimeChange?.(currentTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  useEffect(
    () => () => {
      if (mouseMovingTimeoutRef.current) {
        clearTimeout(mouseMovingTimeoutRef.current)
      }
    },
    []
  )

  const updateMuted = useCallback(
    (value: boolean) => {
      if (videoRef.current) {
        videoRef.current.muted = value
        setMuted(videoRef.current.muted)
      }
    },
    [videoRef]
  )

  const virtualDuration = Math.max(0, typeof duration_ === 'number' ? duration_ : duration - offset_)

  useEffect(() => {
    if (videoRef.current) {
      if (offset_ && (!videoRef.current.currentTime || offset_ > videoRef.current.currentTime)) {
        videoRef.current.currentTime = offset_
      }

      videoRef.current.onplay = () => {
        setIsPlaying(true)
      }

      videoRef.current.onpause = () => {
        setIsPlaying(false)
      }

      videoRef.current.onloadedmetadata = () => {
        setLoaded(true)
      }

      videoRef.current.ondurationchange = () => {
        if (videoRef.current?.duration) {
          setDuration(videoRef.current?.duration)
        } else {
          // eslint-disable-next-line no-console
          console.error('Video duration is not available')
        }
      }

      videoRef.current.ontimeupdate = () => {
        if (!videoRef.current) {
          return
        }

        let t = videoRef.current.currentTime

        if (t - offset_ >= virtualDuration) {
          t = offset_ + virtualDuration
          if (t !== videoRef.current.currentTime) {
            videoRef.current.currentTime = t
          }
          videoRef.current.pause()
        }

        setCurrentTime(t)
      }

      videoRef.current.onvolumechange = () => {
        setVolume(videoRef.current?.volume || 0)
      }

      videoRef.current.onseeking = () => {
        if (!videoRef.current) return
        const t = videoRef.current.currentTime
        setCurrentTime(t)
      }
    }

    if (playerContainerRef?.current) {
      playerContainerRef.current.onfullscreenchange = () => {
        setIsFullScreen((value) => !value)
      }

      playerContainerRef.current.onmousemove = () => {
        if (mouseMovingTimeoutRef.current) {
          clearTimeout(mouseMovingTimeoutRef.current)
        }
        setIsMouseMoving(true)
        mouseMovingTimeoutRef.current = setTimeout(() => {
          setIsMouseMoving(false)
        }, 2000)
      }
    }
  }, [offset_, playerContainerRef, videoRef, virtualDuration])

  const togglePlayPause = () => {
    if (videoRef.current) {
      const t = videoRef.current.currentTime

      if (videoRef.current.paused && Math.round((t - offset_) * 100) / 100 >= Math.round(virtualDuration * 100) / 100) {
        videoRef.current.currentTime = offset_
        videoRef.current.play().then(
          () => {
            // NOTE: do nothing
          },
          (error: any) => {
            if (error.name === 'AbortError') {
              // NOTE: ignore aborted play
              return
            }
            throw error
          }
        )
        return
      }

      if (videoRef.current.paused && !isPlaying) {
        videoRef.current.play().then(
          () => {
            // NOTE: do nothing
          },
          (error: any) => {
            if (error.name === 'AbortError') {
              // NOTE: ignore aborted play
              return
            }
            throw error
          }
        )
      } else if (!videoRef.current.paused && isPlaying) {
        videoRef.current.pause()
      }
    }
  }

  const pause = () => {
    if (videoRef.current && !videoRef.current.paused && isPlaying) {
      videoRef.current.pause()
    }
  }

  const toggleFullScreen = () => {
    if (playerContainerRef?.current) isFullScreen ? document.exitFullscreen() : playerContainerRef.current.requestFullscreen()
  }

  const toggleMute = () => {
    if (videoRef.current) {
      updateMuted(!videoRef.current.muted)
    }
  }

  const updateVolume = (value: number) => {
    if (videoRef.current) {
      videoRef.current.volume = value
      setVolume(value)
    }
  }

  const updateCurrentTime = useCallback(
    (value: number) => {
      if (videoRef.current) {
        videoRef.current.currentTime = Math.max(offset_, value + offset_)
        setCurrentTime(Math.max(offset_, value + offset_))
      }
    },
    [offset_, videoRef]
  )

  const updateCurrentTimeNormalized = useCallback(
    (value: number) => {
      if (videoRef.current) {
        const newCurrentTime = offset_ + virtualDuration * value
        if (isFinite(newCurrentTime)) {
          videoRef.current.currentTime = newCurrentTime
        }
      }
    },
    [videoRef, offset_, virtualDuration]
  )

  const offsetedCurrentTime = currentTime - offset_

  const percentProgression = Math.min(100, Math.max(0, (offsetedCurrentTime * 100) / virtualDuration))
  const percentVolume = Math.min(100, Math.max(0, volume * 100))

  const durationFormatted = format(Math.max(0, virtualDuration * 1000), 'm:ss')
  const currentTimeFormatted = format(Math.max(0, offsetedCurrentTime) * 1000, 'm:ss')

  return {
    currentTime: offsetedCurrentTime,
    currentTimeFormatted,
    duration: virtualDuration,
    durationFormatted,
    isPlaying,
    muted,
    percentProgression,
    percentVolume,
    toggleFullScreen,
    toggleMute,
    togglePlayPause,
    updateCurrentTime,
    updateCurrentTimeNormalized,
    updateMuted,
    updateVolume,
    pause,
    volume,
    loaded,
    isFullScreen,
    isMouseMoving,
  }
}

export default useCustomPlayer
